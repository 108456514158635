import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import Header2 from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
// import '../scss/pages/details.scss';
import get from 'lodash/get'
import ReactMarkdown from "react-markdown/with-html"
import Teamslider from '../components/team-slider'
import TilesSectionLeft from '../components/section-tiles-left'
import TilesSectionRight from '../components/section-tiles-right'
// import PropertiesSection from '../components/SectionProperties/section-properties'
import ContactSection from '../components/SectionContact/section-contact'
import SEO from "../components/Seo/seo";
import Officemap from "../components/office-location/office-location";
import YouTube from '@u-wave/react-youtube';
import SocialShare from "../components/share";
import getVideoId from 'get-video-id';
import { Close, VideoPlayButton, Search, VideoPause, Mute, UnMute } from '../components/icon';
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import $ from 'jquery'
// import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import Grate from "../images/google-review-logo.svg";
import ReviewScore from "../components/reviewScore";
import Reviews from "../components/Reviews/Reviews";
import Valuation from "../components/Valuation/Valuation";
import ContactCard from "../components/ContactCardSection/ContactCard";
import LatestNews from "../components/LatestNews/LatestNews";
//import Breadcrumbs from "../components/breadcrumbs";
import OfficePageBreadcrumb from "../components/OfficePageBreadCrumb/OfficePageBreadCrumb";
import AwardImg from "../images/topPerformer.png";


import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
import Modal from 'react-bootstrap/Modal'
import ReactPlayer from "react-player";
import MarketInsights from "../components/MarketInsights/MarketInsight";

function gotohash(event) {
  var thishash = event;
  $('html, body').animate({
    scrollTop: $(thishash).offset().top - 150
  }, 1000);

}
class OfficeTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showVideo: false, isPlay: false , active : '+ Read More'};
  }

//    handleAccordionClick = () => {
//     var thishash = `#about`;
//     $('html, body').animate({
//       scrollTop: $(thishash).offset().top - 70
//     }, 1000);
    
//     if (this.state.active === '+ Read More') {
//         this.setState({active :  '- Read Less'})
//     } else {
//       this.setState({active :  '+ Read More'})
//     }
// }

  componentDidMount() {
    var checksimilar = $('.office-details #property').length;
    var checkarea = $('.office-details #localarea').length;

    if (checksimilar == 0) {
      $('.scrollproperty').hide();
    }
    if (checkarea == 0) {
      $('.scrolllocalarea').hide();
    }
    $('.secondary-nav li').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
    });
    
  }

  render() {
    var banner_videoid = '';
    const Page = get(this, 'props.data.strapiOffices')
    const News = get(this, 'props.data.allStrapiNewsEvents')
    const GlobalConfig = get(this, 'props.data.strapiGlobalConfig')
    const Modules = get(this, 'props.data.strapiOffices.Modules')
    const TeamHeading = GlobalConfig.Team_Slider_Heading
    const trackerVideo = (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': event,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
      });


    }

    if (Page.Embed_Video_URL != null) {
      banner_videoid = getVideoId(Page.Embed_Video_URL);
    }

    // console.log('showVideo', this.state.showVideo);
    let processedImages = JSON.stringify({});
    if (Page?.imagetransforms?.Banner_Image_Transforms) {
      processedImages = Page.imagetransforms.Banner_Image_Transforms;
    }
    const image_url = Page.Banner_Image && Page.Banner_Image.internal.description ? Page.Banner_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
    //console.log("imageurl",image_url)
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let review_url = Page.URL == 'clapham-estate-agents' ? 'https://www.google.com/search?q=orlando+reid+clapham&rlz=1C1RXQR_enIN1006IN1006&oq=orlando+reid+clapham&aqs=chrome.0.69i59l2j0i22i30l2j0i390j69i60l3.3744j0j7&sourceid=chrome&ie=UTF-8#lrd=0x487605acd1f864b5:0x92bfa6fc6400275c,1,,,' : Page.URL == 'battersea-estate-agents' || Page.URL == 'nine-elms-estate-agents' ? 'https://www.google.com/search?q=orlando+reid+battersea+and+nine+elms&rlz=1C1RXQR_enIN976IN976&oq=orlando+reid+batte&aqs=chrome.1.69i57j69i59j69i60l2j69i61.3630j0j9&sourceid=chrome&ie=UTF-8#lrd=0x487605a48010e231:0xed129ee181ace4dd,1,,,' : Page.URL == 'manchester-property-investments' ? 'https://www.google.com/search?q=orlando+reid+manchester&rlz=1C1RXQR_enIN976IN976&oq=orlando+reid+ma&aqs=chrome.1.69i57j69i59j69i60l3.3768j0j9&sourceid=chrome&ie=UTF-8#lrd=0x487bb1d91e0cc4b1:0x81072e0fb09326d4,1,,,' : Page.URL == 'riyadh-property-investment-uk' ? '/about-us/reviews/' : '';
    let sale_url = '';
    let rent_url = '';
    if (Page.URL == 'clapham-estate-agents') {
      sale_url = '/property/for-sale/in-clapham/';
      rent_url = '/property/to-rent/in-clapham/';
    } else if (Page.URL == 'battersea-estate-agents') {
      sale_url = '/property/for-sale/in-battersea/';
      rent_url = '/property/to-rent/in-battersea/';
    }
    let cta_btn_text = "property valuation";
    let shareText = "Share this Office Details"
    let link_to = "/property-valuation/"
    if(Page?.Select_Office_Location == "London"){
      if(Page?.URL == 'clapham-estate-agents' || Page?.URL == "battersea-estate-agents" || Page?.URL == "nine-elms-estate-agents"){
        cta_btn_text = "Free Property Valuation";
        shareText = null;
      }
    }
    if(Page?.Select_Office_Location == "Manchester"){
      if(Page?.URL == "manchester-property-investments"){
        cta_btn_text = "New Build Homes and Developments";
        link_to = "/property/developments/new-homes/for-sale/in-manchester/sortby-price-asc"
      }
    }
    
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{`{
                "@context":"https://schema.org",
                "@graph":[
                {"@type":"WebSite",
                "@id":"${pageurl}/#website",
                "url":"${pageurl}",
                "name":"${Page.Meta_Title}",
                "description":"${Page.Meta_Description}",
                "potentialAction":{"@type":"SearchAction",
                "target":"https://www.orlandoreid.co.uk/?s={search_term_string}",
                "query-input":"required name=search_term_string"}},
                {"@type":"ImageObject","@id":"${pageurl}/#primaryimage","url":"https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg"},
                {"@type":"WebPage","@id":"${pageurl}/#webpage",
                "url":"${pageurl}",
                "name":"${Page.Meta_Title}",
                "isPartOf":{"@id":"https://www.orlandoreid.co.uk/#website"},
                "primaryImageOfPage":{"@id":"${pageurl}/#primaryimage"},
                "description":"${Page.Meta_Description}"}
                ]    
            }`}</script>
        </Helmet>

        <div className="sub-wrapper office-details">
          {/* header start */}
          <Header2 />
          {/* header end */}

          <SEO title={Page.Meta_Title} description={Page.Meta_Description} />

          {/* content start */}
          <div className="content">
            {/* section banner start */}
            <section className={`details-banner ${Page.Office_Name=="Nottingham Estate Agents"?' nottingham-banner ':''}`}>
              <div className="detail-img contact-img contact-imgx">
                {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}
                  {Page.Banner_Image != null &&
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Banner_Image.bannerimg" attr={{ alt: Page.Office_Name + ' - Orlando Reid' }} imagetransformresult={processedImages} id={Page.strapiId} />

                  }
                  {Page.Embed_Video_URL != null && Page.Embed_Video_URL != '' &&
                    <button className="btn-play"
                      onClick={() => (this.setState({
                        isPlay: true,
                        showVideo: true
                      }))}
                    >
                      <VideoPlayButton />
                    </button>
                  }
                  {
                    this.state.showVideo && banner_videoid !== '' && this.state.isPlay && Page.Embed_Video_URL != null &&
                    <Modal dialogClassName="modal-full-video" show={this.state.showVideo} fullscreen={true} onHide={() => this.setState({showVideo:false})}>
                      <div className="full-modal-close">
                        <a href="javascript:;" onClick={()=>{this.setState({showVideo:false})}}><Close/></a>
                      </div>
                      <ReactPlayer autoplay ref={'#'} url= {Page.Embed_Video_URL} controls = {isIOS && isMobileOnly?true:false} muted={false} playsinline={true} playing={true} onEnded={()=>{this.setState({isPlay:false})}}  width='100%'height='100%' />
                      </Modal>
                  }
                {/* </ScrollAnimation> */}
                <div className="book-valuation-cta d-block d-md-none">
                  {Page?.Select_Office_Location == "Manchester" ?
                    <Link to={"/property/developments/new-homes/for-sale/in-manchester/sortby-price-asc"} className={"btn btn-success"}>New Build Homes and Developments</Link>
                    :
                    <Link to={"/property-valuation/ "} className={"btn btn-success"}>Book a free valuation</Link>
                  }
                </div>
              </div>
              <Container className="d-xl-flex justify-content-xl-end">
                <div className="details-text">
                  { Page?.Select_Office_Location == "London" ?
                    <OfficePageBreadcrumb officeName={Page?.Office_Name}/> : ""}
                  <Link className="back-link" to="/contact/our-offices/">Back to Contact Us</Link>
                  <address className="location-addrs">
                    <h1>{Page.Office_Name}</h1>
                    <div className="office-contact">
                      <span className="office-tel">
                        <a href={`tel:${Page.Phone_Number}`}>{Page.Phone_Number}</a>
                      </span>
                      <span className="room-info"><a href={`mailto:${Page.Email}`}>{Page.Email}</a></span>
                    </div>
                    <div className="btn-wrap d-none d-xl-block">
                      <a href={`/contact/general-enquiry/?contactoffice=${Page.URL}`} className="btn btn-success mb-4">Contact Office</a>

                      {Page.URL === "riyadh-property-investment-uk" ? "" : <Link to={link_to} className="btn btn-default">{cta_btn_text}</Link>}
                      {Page.Title.includes("Nottingham") && <a href="/nottingham-investment-enquiry/" className="btn btn-success nottingham-enquiry-cta">Register for Investments</a>}                  
                    </div>

                    {shareText != null && <SocialShare Title={shareText} />}
                    {review_url !== '' ?
                      <div className="google-rating d-md-flex align-items-md-center">
                        <img src={Grate} alt="img" />
                        <a href={review_url} target="_blank"><ReviewScore /></a>
                      </div> : ''}
                      {Page.URL == 'clapham-estate-agents' ?
                      <div class='prop-btns text-center'>
                        <Link to="/property/for-sale/in-clapham/" className="btn btn-success">Clapham Properties for Sale</Link>
                        <Link to="/property/to-rent/in-clapham/" className="btn btn-default">Clapham Properties to Rent</Link>
                        </div>
                      : Page.URL == 'battersea-estate-agents' ?
                      <div class='prop-btns text-center'>
                      <Link to="/property/for-sale/in-battersea/ " className="btn btn-success">Battersea Properties for Sale</Link>
                      <Link to="/property/to-rent/in-battersea/" className="btn btn-default">Battersea Properties to Rent</Link>
                      </div>
                    : Page.URL == 'riyadh-property-investment-uk' ?
                    <div class='prop-btns text-center'><p>Browse:</p>
                    <Link to="/properties-for-sale-in-london-and-manchester/ " className="btn btn-success">Properties for Sale</Link>
                    <Link to="/property/to-rent/in-london-and-manchester/" className="btn btn-default">Properties to Rent</Link>
                    </div>
                    : Page.URL == 'nine-elms-estate-agents' ?
                    <div class='prop-btns text-center'>
                    <Link to="/property/for-sale/in-nine-elms/" className="btn btn-success">Nine Elms Properties for Sale</Link>
                    <Link to="/property/to-rent/in-nine-elms/" className="btn btn-default">Nine Elms Properties to Rent</Link>
                    </div>
                    : ''}

                  </address>
                </div>
              </Container>
            </section>
            
            {/* section banner end */}
            {/* section text start */}
            <section className="section-text our-offices-text">
              <nav className="secondary-nav our-offices-nav">
                <Container>
                  <ul className="d-flex">
                    <li className="active">
                      <a onClick={() => gotohash('#about')}>about</a>
                    </li>
                    <li>
                      <a onClick={() => gotohash('#location')}>location</a>
                    </li>
                    <li>
                      <a onClick={() => gotohash('#people')}>people</a>
                    </li>
                    <li className="scrolllocalarea">
                      <a onClick={() => gotohash('#localarea')}>local area</a>
                    </li>
                    <li className="scrollproperty">
                      <a onClick={() => gotohash('#property')}>property</a>
                    </li>
                  </ul>
                  <Link className="btn btn-success">book a viewing</Link>
                </Container>
              </nav>
              <Container id="about">
                <Row>
                  <Col xl={8}>
                    
                    <ReactMarkdown source={Page.Intro_Content} escapeHtml={false} />
                    
                      {/* {Page.Intro_Content && 
                        this.state.active === '- Read Less' ? <ReactMarkdown source={Page.Intro_Content} escapeHtml={false} /> : 
                        <ReactMarkdown source={Page.Intro_Content.slice(0,800)} escapeHtml={false} />}
                        {Page.Intro_Content.length > 800 && <a href="javascript:;" className="read-more custom-link" onClick={() => this.handleAccordionClick()}>{this.state.active}</a>}
                        */}
                  </Col>

               
                  <Col xl={4} className="office-intro-right-content">
                  {Page.Show_Award === true &&
                    <div className="award">
                        <Link to="/contact/our-offices/london-offices/battersea-estate-agents/"><img src={AwardImg} alt="award"/></Link>  
                    </div>}
                    <div className="office-right-sticky">
                   
<h2 class="justify-content-center"><strong>Your property is our priority!</strong></h2>
 <a href={`/property-services/rent/new-homes-lettings-experts`} className="btn btn-success mb-4">New Homes Lettings Experts</a>

  <ReactMarkdown source={Page.Opening_hours} escapeHtml={false} />
                    </div>
                    
                  </Col>

                </Row>
              </Container>
            </section>
            {/* section text end */}
            {/* section map start */}

            
            {Modules.map((Modules) => {
              return (
                <>
                  {Modules.Select_Team_Members != null &&
                    <div id="people">
                      <Teamslider data={Modules.Select_Team_Members} TeamHeading={TeamHeading} />
                    </div>
                  }

                  {Modules.Show_Google_Review_Office != null &&
                    <div id="reviews">
                      <Reviews ReviewTitle={Modules.Show_Google_Review_Title} CTAText={Modules.Custom_Button_CTA_Text} CTAView={Modules.Custom_Button_CTA_View} ReviewURL={Modules.Custom_CTA_Review_URL} GoogleReview={Modules.Select_Google_Review.URL} ReviewId={Modules.Show_Review_Office_Id} />
                    </div>
                  }

                  {Modules.Image_Position == "Left" && Modules.Tile_Image != null &&
                    <div id="localarea">
                      <TilesSectionLeft Title={Modules.Tile_Title} Content={Modules.Tile_Content} AriticleId={Page.strapiId} appId="offices" imagetransforms ={Page?.imagetransforms}
                        ShowForm={Modules.Show_Get_Started_Form} Label={Modules.CTA_Label} TileImg={Modules.Tile_Image} TileVideo={Modules.Tile_Embed_Video_URL} CTAUrl={Modules.CTA_Url} CustomUrl={Modules.Custom_CTA_URL} />
                    </div>
                  }
                  {Modules.Image_Position == "Right" && Modules.Tile_Image != null &&
                    <div id="localarea">
                      <TilesSectionRight Title={Modules.Tile_Title} Content={Modules.Tile_Content} AriticleId={Page.strapiId} appId="offices" imagetransforms ={Page?.imagetransforms}
                        ShowForm={Modules.Show_Get_Started_Form} Label={Modules.CTA_Label} TileImg={Modules.Tile_Image} TileVideo={Modules.Tile_Embed_Video_URL} CTAdrodown={Modules.CTA_Dropdown} CTAUrl={Modules.CTA_Url} CustomUrl={Modules.Custom_CTA_URL} />
                    </div>
                  }
                  {/* {Modules.Select_Module == "Featured_Properties" &&
                    <div id="property">
                      <PropertiesSection area={Page.Area} officeId={Page.Office_CRM_ID} />
                    </div>
                  } */}
                  {Modules.Select_Module == "GetIn_Touch" &&
                    <ContactSection getInTouch={GlobalConfig} />
                  }
                  {Modules.Select_Module == 'Office_detail_map' &&
                    <div className="full-map-area" id="location">
                      <Officemap data={Page} Latitude={Page.Latitude} Longitude={Page.Longitude} />
                    </div>
                  }
                  {Modules.Content_After && Modules.Title && Modules.Content_Before && Modules.Phone_Number &&
                    <div id="valuation-request">
                      <Valuation Title={Modules.Title} Content_Before={Modules.Content_Before} Content_After={Modules.Content_After} 
                       Phone_Number={Modules.Phone_Number}/>
                    </div>
                  }
                  {
                    Modules.Section_Title && Modules.Contact_Card_Section &&
                    <div id="contact-card">
                        <ContactCard SectionTitle={Modules?.Section_Title} ContactCard={Modules?.Contact_Card_Section}/>
                    </div>
                  }
                  {
                    Modules.Market_Title && Modules.Market_Tabs &&
                    <MarketInsights MarketTitle={Modules.Market_Title} tabs={Modules.Market_Tabs} />

                  }
                  {Modules?.Latest_News_Title && Modules?.Latest_News_Content &&
                    <LatestNews NewsTitle={Modules?.Latest_News_Title}  NewsContent={Modules?.Latest_News_Content} NewsDetails={News}/>
                  }
                </>
              )
            })}
          </div>
          {/* content end */}

          {/* footer start */}
          <Footer guides={Page.areaguides} title={Page.Office_Name} area={Page.URL} popularSearch="staticdetails" showofficeschema="Yes" footerDetails={GlobalConfig} />
          {/* footer end */}
        </div>
      </>
    )
  }

}

export const pageQuery = graphql`
  query OfficeQuery($slug: String!) {
      strapiOffices(URL: {eq: $slug}) {
        id
        URL
        strapiId
        URL
        Meta_Description
        Meta_Title
        Title
        Office_Address
        Office_Name
        Show_Award
        Select_Office_Location
        Phone_Number
        Office_CRM_ID
        Area
        Embed_Video_URL
        Email
        Latitude
        Longitude
        areaguides {
          URL
          Title
          Select_Areaguide_Location
        }
        Banner_Image {
          publicURL
          internal {
            description
          }
        }
        imagetransforms {
          Tile_Image_Transforms
          Banner_Image_Transforms
        }
        Intro_Content
        Opening_hours
        Contact_Office_Intro
        Modules {
          Latest_News_Title
          Latest_News_Content
          Content_After
          Title
          Content_Before
          Phone_Number
          Market_Title
          Market_Tabs {
           Content
           Title
           market_insights {
                Hint
                Image {
                    childImageSharp {
                      fixed(quality: 90, width: 60 , height: 60) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                }
                Prefix
                Value
                Suffix
                Title
            }
          }
          CTA_Label
          Custom_CTA_URL
          Select_Module
          Image_Position
          Tile_Content
            Tile_Image {
                internal {
                    description
                }
            }
          Tile_Title
          Tile_Embed_Video_URL

          Select_Team_Members {
            id
            Name
            URL
            Designation
            Phone
            Embed_Video_URL
            Staff_Image {
              internal {
                description
              }
            }
            imagetransforms {
              Staff_Image_Transforms
            }
          }

          Custom_Button_CTA_Text
          Custom_Button_CTA_View
          Custom_CTA_Review_URL
          Select_Google_Review {
            URL
          }
          Show_Google_Review_Office
          Show_Google_Review_Title
          Show_Review_Office_Id

          Section_Title
          Contact_Card_Section {
              Category
              Contact_CTA_Label
              Contact_CTA_URL
              Phone_Number
              Property_Result_CTA
              Property_Result_URL
              Valuation_CTA_Label
              Valuation_CTA_Link
              id
          }

        }
      }
      allStrapiNewsEvents(filter: {id: {ne: "News-events_5fcf647332b45e3f44c85c0f"}}, sort: {order: DESC, fields: id} , limit : 10) {
        edges {
          node {
            strapiId
            URL
            Select_Page
            Intro_Image {
              internal {
                description
              }
            }
            Title
            Select_Categories {
              Name
              URL
            }
            News_Date
            id
            imagetransforms {
              Intro_Image_Transforms
            }
          }
        }
      }
      strapiGlobalConfig {
          Team_Slider_Heading 
          Get_In_Touch_Content
          Get_In_Touch_Embed_Video_Link
          Footer_Office_Address
          Footer_Newsletter_Title
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Get_In_Touch_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Team_Box_Content
          Team_Box_Video_URL
          Team_Box_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Clients {
            Client
            Client_Image {
              publicURL
            }
            Client_Url
          }
          Footer_Links {
            label
            Menu_item {
              URL
              Secondary_URL
            }
          }
        }
    }
`
export default OfficeTemplate
